import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import Link from '../link/link';

import Button from '../button/button';

import styles from './news-list.module.scss';

const PageLink = (props) => {
  if (props.test) {
    return (
      <Link className={props.className} to={props.url}>
        {props.text}
      </Link>
    );
  }
  else {
    return false;
  }
};

const NewsList = ({ data, paging }) => {
  const { index, pageCount, pathPrefix } = paging;
  const first = index === 1;
  const previousUrl =
    index - 1 == 1 ? `/${pathPrefix}/` : (index - 1).toString();
  const nextUrl = first
    ? `/${pathPrefix}/${(index + 1).toString()}`
    : (index + 1).toString();

  return (
    <div>
      <div className={styles.newsList}>
        {data.map((item) => {
          const node = item.node;

          // Grab the image.
          let image = '';
          if (
            node.relationships &&
            node.relationships.field_image &&
            node.relationships.field_image.localFile &&
            node.relationships.field_image.localFile.childImageSharp
          ) {
            image = node.relationships.field_image.localFile.childImageSharp;
          }

          return (
            <article
              aria-label={node.title}
              className={styles.newsItem}
              key={node.drupal_id}
            >
              {image && (
                <Img
                  alt={node.field_image.alt}
                  style={{ display: 'none' }}
                  className={styles.img}
                  resolutions={image.resolutions}
                />
              )}
              {image && (
                <Img
                  alt={node.field_image.alt}
                  className={styles.mobileImg}
                  sizes={image.sizes}
                />
              )}
              <div className={styles.newsContent}>
                <h2 className={styles.title}>{node.title}</h2>
                <p className={styles.date}>{node.created}</p>
                <p className={styles.summary}>{node.body.summary}</p>
                <Button
                  aria-label={`Continue reading ${node.title}`}
                  to={node.path.alias}
                  color="blueLight"
                  textStyle="upper"
                >
                  Continue Reading
                </Button>
              </div>
            </article>
          );
        })}
      </div>
      <div className={styles.pagination}>
        <PageLink
          className={styles.prev}
          test={!first}
          url={previousUrl}
          text="More Recent News"
        />
        <PageLink
          className={styles.next}
          test={index !== pageCount}
          url={nextUrl}
          text="Older News"
        />
      </div>
    </div>
  );
};

NewsList.propTypes = {
  data: PropTypes.array.isRequired
};

export default NewsList;
