import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Container from '../components/container/container';
import DefaultLayout from '../components/default-layout/default-layout';
import NewsList from '../components/news-list/news-list';
import SEO from '../components/seo/seo';
import WYSIWYG from '../components/wysiwyg/wysiwyg';

const NewsPage = ({ data, pageContext }) => {
  const { group } = pageContext;

  return (
    <DefaultLayout data={data}>
      <SEO
        title="Latest News"
        description="News and updates for the City Springs development in Sandy Springs, GA."
        siteConfig={data.site.siteMetadata}
        pageUrl="/news"
      />
      <Container>
        <WYSIWYG>
          <h1 className="heading-2">Latest News</h1>
          <br />
          <br />
        </WYSIWYG>
        <NewsList data={group} paging={pageContext} />
      </Container>
    </DefaultLayout>
  );
};

NewsPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object.isRequired
};

export default NewsPage;

export const query = graphql`
  query NewsPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allTaxonomyTermNewsTags {
      edges {
        node {
          name
          drupal_internal__tid
          drupal_id
        }
      }
    }
  }
`;
