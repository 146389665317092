import React from 'react';
import PropTypes from 'prop-types';

import styles from './container.module.scss';

const Container = (props) => (
  <div className={styles.container} {...props}>
    {props.children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired
};

export default Container;
